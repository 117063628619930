<template>
  <div class="container">
    <div class="columns">
      <!-- Validating token state -->
      <div
        v-if="state==='validating-token'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered">
            <h3 class="">
              Please wait...
            </h3>
            <p>We are validation your account and e-mail...</p>
          </div>
        </div>
      </div>

      <!-- Token is valid state -->
      <div
        v-if="state==='token-is-valid'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered">
            <h3 class="">
              E-mail confirmation completed
            </h3>
            <p>
              Your e-mail has been succesfully confirmed.
            </p>
            <router-link :to="{ name: 'login' }">
              Go to login
            </router-link>
          </div>
        </div>
      </div>

      <!-- Token is valid state -->
      <div
        v-if="state==='email-already-valid'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered">
            <h3 class="">
              E-mail confirmation completed
            </h3>
            <p>
              Your e-mail is already confirmed, you don't have to follow the e-mail confirmation instruction again.
            </p>
            <router-link :to="{ name: 'login' }">
              Go to login
            </router-link>
          </div>
        </div>
      </div>

      <!-- Validating token state -->
      <div
        v-if="state==='wrong-way'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <h3 class="has-text-centered">
            Wrong way...
          </h3>
          <p>It looks like you got to this page incorrectly</p>
          <p>If you are not able to log in, please contact the system administrators.</p>
          <p class="has-text-centered">
            <router-link :to="{ name: 'login' }">
              Go to login
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RegisterAmount",
  data() {
    return {
      doingAjax: false,
      state: "validating-token",
    };
  },
  async beforeMount() {
    if (!this.$route.query.token) {
      this.state = "wrong-way";
    } else {
      this.emailConfirmation();
    }
  },
  methods: {
    async emailConfirmation() {
      const data = await this.$store.dispatch(
        "login/emailConfirmation",
        this.$route.query.token
      );

      if(data === false) {
        // Couldn't validate, should display an error to the user
        this.state = "wrong-way";
      } else {
        const response = this.$helpers.findDeepProp('details.status', data);
        if (response === 'email-already-valid') {
          this.state = "email-already-valid";
        } else {
          this.state = "token-is-valid";
        }
      }
    },
  },
};
</script>
